import { Injectable } from '@angular/core';

@Injectable()
export class CookiesService {

  constructor() { }

  public getPolicyCookie() {
    return !!localStorage.getItem('COOKIE_POLICY');
  }
  public setPolicyCookie(): void {
    try {
      localStorage.setItem('COOKIE_POLICY', 'OK');
    } catch (error) {
      console.error();
    }
  }
}
