import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MailsService } from 'src/app/core/services/mails.service';
import { CookiesService } from 'src/app/core/services/cookies.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public display = false;
  public contactFormGroup: FormGroup;
  public isPolicyAccpeted: boolean;
  constructor(private formBuilder: FormBuilder,
              private mailer: MailsService,
              public cookiesServ: CookiesService) { }

  public ngOnInit() {
    this.isPolicyAccpeted = this.cookiesServ.getPolicyCookie();
    this.contactFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      cp: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  public contactClientForm() {
    if (this.contactFormGroup.valid) {
      // console.warn(this.mailer.informationFromclient(this.contactFormGroup.getRawValue));
      this.mailer.informationFromclient(this.contactFormGroup.getRawValue()).subscribe((res) => {
        console.log(res);
      });
    }
  }
}
