import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class MailsService {

  private headers =  new HttpHeaders({
    Authorization : 'Bearer Code',
    'Content-Type' : 'application/json'
  });

  constructor(private http: HttpClient) { }

  public informationFromclient(data) {
    const body = {};
    // return this.http.get('https://api.github.com/users?since=135').pipe(map(res => res));
    return this.http.post('https://api.sendgrid.com/v3/mail/send', body, { headers: this.headers } )
                    .pipe(
                      map((mailData) => mailData)
                    );
  }

}
